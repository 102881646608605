import React, { useState } from "react";
import "../theme/auth.less";
import AuthTemplate from "../components/Auth/AuthTemplate";
import { useAuth } from "../hooks/useAuth";
import { AuthContext } from "../context/AuthContext";

const AuthPage = () => {
  const { mentor, mentorData, token, user, login, logout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        mentor,
        mentorData,
        user,
        login,
        logout,
      }}
    >
      <AuthTemplate />
    </AuthContext.Provider>
  );
};

export default AuthPage;
