import React, { useContext, useState } from "react";
import "./Signin.less";
import firebase from "firebase";
import { app, googleProvider } from "../../../../firebaseConfig";
import { AuthContext } from "../../../context/AuthContext";
import { Typography, Form, Input, Button, message } from "antd";
import Google from "../../../assets/auth/google.svg";
import ErrorMessage from "../../../assets/auth/errorMessage.svg";
import { Link, navigate } from "gatsby";

const { Text, Title } = Typography;

const Signin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { email, password } = values;

    setIsLoading(true);
    let idToken: string | undefined;
    try {
      const response = await app
        .auth()
        .signInWithEmailAndPassword(email, password);

      idToken = await response?.user?.getIdToken();
      console.log({ idToken });
    } catch (err: any) {
      setIsLoading(false);
      if (err.code === "auth/user-not-found") {
        return message.error("Invalid Email");
      } else if (err.code === "auth/wrong-password") {
        return message.error("Invalid Password");
      } else {
        return message.error("Something went wrong");
      }
    }

    try {
      const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const loginData = await loginResponse.json();
      if (loginData) {
        const { mentor, mentorData, token, user } = loginData;
        authContext.login(mentor, mentorData, token, user);
        if (typeof window !== "undefined") {
          if (user.isReferral) {
            navigate("/referral");
          } else {
            navigate("/");
          }
        }
        setIsLoading(false);
      } else {
        return setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message.error("Something went wrong");
    }
  };

  const signInWithGoogle = async () => {
    setIsLoading(true);
    let idToken: string | undefined;

    try {
      const resp = await app.auth().signInWithPopup(googleProvider);
      idToken = await resp?.user?.getIdToken();
      console.log({ idToken });
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
      if (error.code === "auth/popup-closed-by-user") {
        return message.error("Popup was closed");
      }
      return message.error("Something went wrong");
    }

    try {
      const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const loginData = await loginResponse.json();
      if (loginData) {
        if (loginData.userNotFound) {
          return message.error("Failure");
        }
        const { mentor, mentorData, token, user } = loginData;
        authContext.login(mentor, mentorData, token, user);
        console.log({ loginData });

        if (typeof window !== "undefined") {
          if (user.isReferral) {
            navigate("/referral");
          } else {
            navigate("/");
          }
        }
        setIsLoading(false);
      } else {
        return setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message.error("Something went wrong");
    }
    setIsLoading(false);
  };

  return (
    <div className="signInFormDiv">
      <Title className="signinTitle">Sign In to Mentro.</Title>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={<Text className="text">EMAIL</Text>}
          name="email"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter your Email
                </Text>
              ),
            },
            {
              pattern:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a valid Email
                </Text>
              ),
            },
          ]}
        >
          <Input placeholder="Enter your Email" />
        </Form.Item>
        <Form.Item
          label={<Text className="text">PASSWORD</Text>}
          name="password"
          wrapperCol={{ span: 24 }}
          className="passwordForm"
          rules={[
            {
              required: true,
              message: (
                <Text className="errorMessage">
                  <img src={ErrorMessage} alt="errorMessage" />
                  Please enter a Password
                </Text>
              ),
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Text className="forgotPass">forgot password?</Text>
        <div className="continueDiv">
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="continueBtn"
              loading={isLoading}
            >
              Continue
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className="sep">
        <span className="or">OR</span>
      </div>
      <div className="googleAuth">
        <Button onClick={signInWithGoogle}>
          <img src={Google} alt="google logo" />
          <Text>Sign in with google</Text>
        </Button>
      </div>
      <div className="policyDiv">
        <Text className="text">
          By continuing this, you are agreeing to Mentro’s{" "}
          <span onClick={() => window.open("https://info.mentro.tech/")}>
            Privacy Policy
          </span>
        </Text>
      </div>
    </div>
  );
};
export default Signin;
