import React, { useState } from "react";
import "./AuthTemplate.less";
import { Col, Row, Typography } from "antd";
import AuthDesign from "../../../assets/auth/authDesign.svg";
import Signin from "../Signin";
import Signup from "../Signup";

const { Title } = Typography;

const AuthTemplate = () => {
  const [isSignin, setIsSignin] = useState(true);

  return (
    <div className="authParent">
      <Row className="authRow">
        <Col sm={14} className="leftCol">
          <Title className="title">
            Get our free resources to unleash your success in any subject.
          </Title>
          <img src={AuthDesign} alt="authDesign" />
        </Col>
        <Col sm={10} className="rightCol">
          <div className="rightDiv">
            <div className="switchDiv">
              <ul>
                <li
                  className={isSignin ? "activeList" : "unActiveList"}
                  onClick={() => setIsSignin(true)}
                >
                  Signin
                </li>
                <li
                  className={!isSignin ? "activeList" : "unActiveList"}
                  onClick={() => setIsSignin(false)}
                >
                  Signup
                </li>
              </ul>
            </div>
            {isSignin ? <Signin /> : <Signup />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthTemplate;
