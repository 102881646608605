import React, { useContext, useState } from "react";
import "./Signup.less";
import firebase from "firebase";
import { AuthContext } from "../../../context/AuthContext";
import { app } from "../../../../firebaseConfig";
import { Typography, Form, Input, Button, Progress, message } from "antd";
import BackIcon from "../../../assets/auth/backIcon.svg";
import Google from "../../../assets/auth/google.svg";
import ErrorMessage from "../../../assets/auth/errorMessage.svg";
import { Link, navigate } from "gatsby";
const { Text, Title } = Typography;

const Signup = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    institution: "",
    imageUrl: "",
  });

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinishProfile = (values) => {
    const { userName, institution } = values;
    setFormData({ ...formData, userName, institution });
    console.log(values);
    console.log("IM IN ONFINISH PROFILE", current);
    next();
  };

  const onFinishAuthData = async (values) => {
    const { email, password } = values;
    setFormData({ ...formData, email, password });
    console.log(values);
    console.log({ formData });

    setIsLoading(true);
    let idToken: string | undefined;
    try {
      const response = await app
        .auth()
        .createUserWithEmailAndPassword(email, password);
      idToken = await response?.user?.getIdToken();
      console.log({ idToken });
    } catch (error: any) {
      setIsLoading(false);
      if (error.code === "auth/email-already-in-use") {
        return message.error("Email is already used!");
      } else {
        return message.error("Something went wrong");
      }
    }
    console.log({ idToken });

    try {
      const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            userName: formData.userName,
            email: email,
            password: password,
            institution: formData.institution,
            imageUrl: "",
          }),
        }
      );

      const loginData = await loginResponse.json();
      if (loginData) {
        const { mentor, mentorData, token, user } = loginData;
        authContext.login(mentor, mentorData, token, user);
        if (typeof window !== "undefined") {
          if (user.isReferral) {
            navigate("/referral");
          } else {
            navigate("/");
          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message.error("Something went wrong");
    }
  };

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  const signUpWithGoogle = async () => {
    setIsLoading(true);
    let idToken: string | undefined;

    try {
      const resp = await app.auth().signInWithPopup(googleProvider);
      idToken = await resp?.user?.getIdToken();
      console.log({ idToken });
      console.log({ resp });
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      if (error.code === "auth/popup-closed-by-user") {
        return message.error("Popup was closed");
      }
      return message.error("Something went wrong");
    }

    try {
      const loginResponse = await fetch(
        `${process.env.GATSBY_BE_URL}/users/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      const loginData = await loginResponse.json();
      if (loginData) {
        if (loginData.userNotFound) {
          return message.error("Failure");
        }
        const { mentor, mentorData, token, user } = loginData;
        authContext.login(mentor, mentorData, token, user);
        console.log({ loginData });

        if (typeof window !== "undefined") {
          if (user.isReferral) {
            navigate("/referral");
          } else {
            navigate("/");
          }
        }
        setIsLoading(false);
      } else {
        return setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return message.error("Something went wrong");
    }
    setIsLoading(false);
  };

  const steps = [
    {
      title: "profileData",
      content: (
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishProfile}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={<Text className="text">NAME</Text>}
            name="userName"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Name
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Name" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">COLLEGE/ORGANIZATION</Text>}
            name="institution"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your College/Organization
                  </Text>
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <div className="continueDiv">
              <Button
                type="text"
                htmlType="submit"
                className="continueBtn"
                loading={isLoading}
              >
                Next
              </Button>
            </div>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "authData",
      content: (
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishAuthData}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={<Text className="text">EMAIL</Text>}
            name="email"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter your Email
                  </Text>
                ),
              },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a valid Email
                  </Text>
                ),
              },
            ]}
          >
            <Input placeholder="Enter your Email" />
          </Form.Item>
          <Form.Item
            label={<Text className="text">CREATE PASSWORD</Text>}
            name="password"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter a Password
                  </Text>
                ),
              },
              {
                min: 6,
                message: (
                  <Text className="errorMessage">
                    <img src={ErrorMessage} alt="errorMessage" />
                    Please enter at least 6 characters
                  </Text>
                ),
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <div className="buttonsDiv">
            <Button type="text" className="backBtn" onClick={prev}>
              Back
            </Button>
            <Button type="text" className="backIconBtn" onClick={prev}>
              <img src={BackIcon} alt="back icon" />
            </Button>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="continueBtn"
                loading={isLoading}
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </Form>
      ),
    },
  ];

  return (
    <div className="signUpFormDiv">
      {/* <Steps current={current} className="stepsAntd">
        {steps.map((item, index) => (
          <Step
            key={item.title}
            className="stepAntd"
            icon={
              current >= index ? (
                <img src={AuthStepIcon} alt="checked" />
              ) : (
                <img src={AuthStepEmptyIcon} alt="un checked" />
              )
            }
          />
        ))}
      </Steps> */}
      <Text className="progressCount">{current + 1}/2</Text>
      <Progress
        showInfo={false}
        percent={(current * 100 + 2) / 2}
        strokeColor="#4caf50"
      />
      <Title className="signupTitle">Sign Up to Mentro.</Title>
      <div className="steps-content">{steps[current].content}</div>
      <div className="sep">
        <span className="or">OR</span>
      </div>
      <div className="googleAuth">
        <Button onClick={signUpWithGoogle}>
          <img src={Google} alt="google logo" />
          <Text>Sign up with google</Text>
        </Button>
      </div>
      <div className="policyDiv">
        <Text className="text">
          By continuing this, you are agreeing to Mentro’s{" "}
          <span onClick={() => window.open("https://info.mentro.tech/")}>
            Privacy Policy
          </span>
        </Text>
      </div>
    </div>
  );
};
export default Signup;
